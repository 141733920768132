<template>
  <!-- XS -->
  <v-data-table
    v-if="$vuetify.breakpoint.xs"
    :headers="xsHeaders"
    :items="teams"
    hide-default-footer
    :mobile-breakpoint="0"
    :options="{itemsPerPage: -1}"
    class="elevation-1 t2"
    dense
  >
    <template v-slot:body="{ items }">
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td class="text-center pr-1" width="30px">
            <!-- IF FORFIET -->
            <v-icon v-if="item.forfeit" color="red">fas fa-medkit</v-icon>
            <!-- ELSE IF SLOTS & !LOGO -->
            <v-avatar v-else-if="teamColorsInverse && !item.logo" size="24" :color="teamColorsInverse[item.slot - 1]">
              <span class="white--text">{{item.slot}}</span>
            </v-avatar>
            <span v-else class="text-no-wrap">
              {{((item.dRank || item.rank) === 999 ? '' : (item.dRank || item.rank)) | ordinal}}
            </span>
            <!-- IF LOGO -->
            <v-img
              v-if="item.logo"
              :src="item.logo"
              height="36"
              max-width="48"
              contain
            ></v-img>
          </td>

          <td class="text-left border-right clickable color3--text" v-if="usePlayerNames(item)">
            <div
              @click.stop="teamClick(item.teamId)"
              :class="{forfeit: item.forfeit}"
              class="color3--text"
              v-for="(name, i) in item.playerNames"
              :key="`name_${i}`"
            >{{name}}</div>
          </td>
          <td v-else @click.stop="teamClick(item.teamId)" class="text-left border-right clickable color3--text">
            {{item.name}}
          </td>
          <td class="text-center" v-if="totalPoints">{{item.pointsFor}}</td>
          <td class="text-center" v-else>{{item.wins}} - {{item.losses}}</td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
  <!-- SM AND UP-->
  <v-data-table
    v-else
    :headers="newHeaders"
    :items="teams"
    hide-default-footer
    class="elevation-1 t2 br"
    :options="{itemsPerPage: -1}"
    :dense="dense"
  >
    <template v-slot:[`item.rank`]="{ item }">
      <v-icon color="red" v-if="item.forfeit">fas fa-medkit</v-icon>
      <span v-else class="text-no-wrap" :class="{title: !advanced || $vuetify.breakpoint.mdAndUp}">
        {{((item.dRank || item.rank) === 999 ? '' : (item.dRank || item.rank)) | ordinal}}
      </span>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <table class="nested">
        <tr>
          <td class="pa-0" width="30px" v-if="teamColorsInverse">
            <v-avatar size="24" :color="teamColorsInverse[item.slot - 1]">
              <span class="white--text">{{item.slot}}</span>
            </v-avatar>
          </td>
          <td v-if="usePlayerNames(item)">
            <div
              class="subtitle-1 text-no-wrap clickable color3--text d-flex align-center"
              :class="{sm: advanced && $vuetify.breakpoint.smAndDown, forfeit: item.forfeit }"
              @click.stop="teamClick(item.teamId)"
            >
              <v-img
                v-if="item.logo"
                :src="item.logo"
                height="36"
                max-width="48"
                contain
                class="mr-6"
              ></v-img>
              <span class="grey--text text-end" style="font-size: 0.65rem; width: 12px;" v-if="!hideSeeds && item.seed">{{hideSeeds ? '' : item.seed || ''}}</span>
              <div>
                <div
                  @click.stop="teamClick(item.teamId)"
                  :class="{forfeit: item.forfeit}"
                  v-for="(name, i) in item.playerNames"
                  :key="`name_${i}`"
                >
                  {{name}}
                </div>
              </div>
              <div></div>
            </div>
          </td>
          <td v-else>
            <div
              class="subtitle-1 text-no-wrap clickable color3--text d-flex align-center"
              :class="{sm: advanced && $vuetify.breakpoint.smAndDown, forfeit: item.forfeit}"
              @click.stop="teamClick(item.teamId)"
            >
              <v-img
                v-if="item.logo"
                :src="item.logo"
                height="36"
                max-width="48"
                contain
                class="mr-6"
              ></v-img>
              <span class="grey--text text-end" style="font-size: 0.65rem; width: 12px;" v-if="!hideSeeds && item.seed">{{hideSeeds ? '' : item.seed || ''}}</span>
              {{item.name}}
            </div>
            <div
              v-if="!noRep"
              class="text-no-wrap"
              :class="{sm: advanced && $vuetify.breakpoint.smAndDown, forfeit: item.forfeit, 'pl-3': !!(hideSeeds ? '' : item.seed || '')}"
              style="color: rgba(0,0,0,0.54);"
            >
              {{getRep(item.teamId)}}
            </div>
          </td>
        </tr>
      </table>
    </template>
    <template v-slot:[`item.wins`]="{ item }">
      <span class="text-no-wrap" :class="{headline: !advanced || $vuetify.breakpoint.mdAndUp}">
        {{item.wins}}
      </span>
    </template>
    <template v-slot:[`item.losses`]="{ item }">
      <span class="text-no-wrap" :class="{headline: !advanced || $vuetify.breakpoint.mdAndUp}">
        {{item.losses}}
      </span>
    </template>
    <template v-slot:[`item.duals`]="{ item }">
      <span class="text-no-wrap" :class="{headline: !advanced || $vuetify.breakpoint.mdAndUp}">
        {{item.dualWins}} - {{item.dualLosses}}
      </span>
    </template>
    <template v-slot:[`item.matches`]="{ item }">
      <span class="text-no-wrap" :class="{headline: !advanced || $vuetify.breakpoint.mdAndUp}">
        {{item.matchWins}} - {{item.matchLosses}}
      </span>
    </template>
    <template v-slot:[`item.sets`]="{ item }">
      <span class="text-no-wrap" :class="{headline: !advanced || $vuetify.breakpoint.mdAndUp}">
        {{item.setWins}} - {{item.setLosses}}
      </span>
    </template>
    <template v-slot:[`item.pointsFor`]="{ item }">
      <span class="text-no-wrap" :class="{headline: !advanced || $vuetify.breakpoint.mdAndUp}">
        {{item.pointsFor}}
      </span>
    </template>
    <template v-slot:[`item.pointsAgainst`]="{ item }">
      <span class="text-no-wrap" :class="{headline: !advanced || $vuetify.breakpoint.mdAndUp}">
        {{item.pointsAgainst}}
      </span>
    </template>
    <template v-slot:[`item.pointDiff`]="{ item }">
      <span class="text-no-wrap" :class="{headline: !advanced || $vuetify.breakpoint.mdAndUp}">
        {{item.pointDiff}}
      </span>
    </template>
    <template v-slot:[`item.pointDiffRatio`]="{ item }">
      <span class="text-no-wrap" :class="{headline: !advanced || $vuetify.breakpoint.mdAndUp}">
        {{item.pointDiffRatio | fixed2}}
      </span>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { usePlayerNames } from '@/classes/HelperFunctions'

export default {
  props: [
    'teams',
    'advanced',
    'division',
    'teamColorsInverse',
    'tournament',
    'showSummaries',
    'isMatch',
    'isDuals',
    'hideSeeds',
    'dense',
    'totalPoints',
    'matchPoints'
  ],
  computed: {
    ...mapGetters(['getTeam']),
    xsHeaders () {
      return [
        { align: 'left', sortable: false },
        { text: 'Team', align: 'left', sortable: false, value: 'name' },
        { text: this.totalPoints ? 'Total Points' : 'Record', align: 'center', sortable: false }
      ]
    },
    newHeaders () {
      const m = this.isMatch
      const a = this.advanced
      const d = this.isDuals && this.division.dualText.startsWith('D')
      const t = this.totalPoints
      const mp = this.matchPoints
      return [
        { text: this.showSummaries ? 'Finish' : 'Standing', align: 'center', sortable: false, value: 'rank', show: true },
        { text: 'Team Name', align: 'left', sortable: false, value: 'name', show: true },
        { text: 'Total Points', align: 'center', sortable: false, value: 'pointsFor', show: t },
        { text: 'Wins', align: 'center', sortable: false, value: 'wins', show: (!a && !t) || (a && !m) },
        { text: 'Losses', align: 'center', sortable: false, value: 'losses', show: (!a && !t) || (a && !m) },
        { text: `${this.division.dualText}s`, align: 'center', sortable: false, value: 'duals', show: a && d },
        { text: 'Matches', align: 'center', sortable: false, value: 'matches', show: a && m },
        { text: 'Sets', align: 'center', sortable: false, value: 'sets', show: a && m },
        { text: 'MP', align: 'center', sortable: false, value: 'matchPoints', show: a && mp },
        { text: 'PF', align: 'center', sortable: false, value: 'pointsFor', show: a && !t },
        { text: 'PA', align: 'center', sortable: false, value: 'pointsAgainst', show: a },
        { text: 'PD', align: 'center', sortable: false, value: 'pointDiff', show: a },
        { text: 'PR', align: 'center', sortable: false, value: 'pointDiffRatio', show: a }
      ].filter(f => f.show)
    },
    noRep () {
      return this.isDuals || (this.division && this.division.props.includes('noRep')) || this.division.isNcaa
    }
  },
  methods: {
    usePlayerNames (team) {
      return usePlayerNames(team, this.division, this.isDuals, this.$vuetify.breakpoint.xs)
    },
    teamClick (id) {
      this.$router.push({ name: 'division-team', params: { tournamentId: this.tournament.id, teamId: id } })
    },
    getRep (teamId) {
      var x = this.getTeam(teamId)
      return x.getRep(this.division.repField)
    }
  }
}
</script>

<style scoped>
.sm {
  font-size: smaller !important;
}
.forfeit {
  text-decoration: line-through;
}
.v-avatar span {
  font-size: 13px;
}
.nested td {
  border: none !important;
}
.nested td > div {
  line-height: initial;
}

>>> td, >>> th {
  padding: 0 4px !important;
  border-right: 1px solid rgba(0,0,0,0.12);
}
</style>
